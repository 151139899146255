@use "@causevest/ui-kit/styles";

.dates {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 20px;

  > * {
    min-width: 47%;

    .MuiDateRangeCalendar-root > *:first-child {
      display: none !important;
    }
  }
}

@media (max-width: 885px) {
  .dates > * {
    min-width: auto;
    width: 45%;
  }
}

@media (max-width: styles.$sm) {
  .dates {
    gap: 15px;
    flex-wrap: wrap;

    > * {
      min-width: 100%;
      width: 100%;
    }
  }
}
