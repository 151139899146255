@use "@causevest/ui-kit/styles";

.wrapper {
  gap: 25px;
}

.title {
  color: styles.$color-black-6;
  font-size: 22px;
  font-weight: 300;
  white-space: nowrap;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
}

.btn {
  width: 100%;
  height: 35px;
}
