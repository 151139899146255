@use "@causevest/ui-kit/styles";

.funds {
  &__count {
    font-size: 16px;

    &._accented strong {
      font-size: 20px;
    }
  }
  &__bar {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 8px;
    border-radius: 8px;
    background: styles.$color-grey-5;
    margin-top: 5px;

    &._active {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
      background: linear-gradient(312deg, styles.$color-blue-2 -59.61%, styles.$color-blue-3 73.4%);
    }
  }
}
