@use "@causevest/ui-kit/styles";

.wrapper {
  position: relative;
  width: 100%;
  height: 290px;
  overflow: hidden;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(15px);
}

.inner {
  position: relative;
  z-index: 1;
  margin: auto;
  gap: 10px;
  align-items: center;
}

.title {
  text-align: center;
  color: #ECECEC;
  font-weight: 300;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.3;
}

.btn {
  width: 100px;
  height: 28px;
  background: styles.$color-white;
}
