@use "@causevest/ui-kit/styles";

.title {
  color: styles.$color-grey-7;
  font-size: 14px;
  white-space: nowrap;
  @include styles.textEllipsis(120px);
}

.image {
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 50%;
}
