@use "@causevest/ui-kit/styles";

.header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: styles.$color-white;
  box-shadow: 0 2px 30px rgba(13, 58, 115, 0.1);

  &__logo {
    max-height: 40px;

    &._desktop {
      display: block;
    }

    &._mobile {
      display: none;
    }
  }

  &__menu {
    &._mobile {
      display: none;
    }

    &._desktop {
      display: block;
    }
  }
}

.left {
  gap: 23px;
}

.menuList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;

  &__item {
    font-size: 14px;
    padding: 5px 10px;
    color: styles.$color-black;

    > a {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    &._active {
      > a {
        color: styles.$color-blue;
      }
    }

    &._logIn {
      color: styles.$color-black;
      font-size: 14px;

      &:hover {
        color: styles.$color-blue;
        background: styles.$color-white;
      }
    }

    > a {
      color: styles.$color-black;
      text-decoration: none;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: styles.$color-blue;
      }
    }
  }
}

.divider {
  width: 1px;
  height: 24px;
  background: styles.$color-black-2;
  margin-right: 10px;
}

.signUpBtn {
  width: 85px;
  height: 27px;
  font-size: 14px !important;
  font-weight: 400;
  text-transform: inherit;
  background: styles.$color-light-blue;
}

@media (max-width: styles.$md) {
  .header {
    &__logo {
      max-height: 32px;

      &._desktop {
        display: none;
      }

      &._mobile {
        display: block;
      }
    }

    &__search {
      display: none;
    }

    &__menu {
      &._mobile {
        display: block;
      }

      &._desktop {
        display: none;
      }
    }
  }

  .menuList {
    flex-direction: column;
    align-items: flex-start;

    &__item {
      padding: 5px 25px 5px 10px;
    }
  }

  .divider {
    &__wrapper {
      display: none;
    }
  }

  .signUpBtn {
    width: 113px;
    margin-left: 10px;
    padding: 5px 18px;
  }
}
