@use "@causevest/ui-kit/styles";

.wrapperLink {
  display: block;
  text-decoration: none;
  color: inherit;
  height: 100%;
}

.wrapper {
  min-height: 378px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid styles.$color-blue-2;
  background: styles.$color-white;
}

.img {
  position: relative;
  width: 100%;
  height: 155px;
  overflow: hidden;
  border-bottom: 1px solid styles.$color-light-grey;

  &._liked {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 73.16%
      );
    }
  }
}

.imgInfo {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 0;
  z-index: 1;

  &__item {
    gap: 4px;

    > a {
      color: styles.$color-white;
      font-size: 12px;
      font-weight: 500;
    }

    &:first-child {
      margin-left: 17px;
    }

    &:last-child {
      margin-right: 17px;
    }
  }
}

.status {
  position: absolute;
  top: 10px;
  right: 0;
  background: styles.$color-blue-3;
  color: styles.$color-white;
  font-size: 12px;
  padding: 6px 12px;
  border-radius: 8px 0 0 8px;
}

.inner {
  padding: 20px;
  background: styles.$color-white;
  min-height: 180px;
}

.title {
  @include styles.multilineEllipsis(2, 100%);
  font-size: 14px;
  font-weight: 500;
  color: styles.$color-black-6;
}

.text {
  word-break: break-word;
  white-space: pre-line;
  margin-top: 10px;
  min-height: 63px;
  font-size: 14px;
  color: styles.$color-black-5;
  @include styles.multilineEllipsis(3, 100%);
}

.donors {
  font-size: 10px;
  color: styles.$color-grey-4;
}

.bottom {
  margin-top: 20px;
}

.creator {
  &__info {
    margin: 18px 0 9px;

    > p {
      white-space: nowrap;
      font-size: 12px;
    }
  }
}
