@use "@causevest/ui-kit/styles";

.inner {
  overflow: hidden;
}

.firstScreen {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 55px;
  padding-bottom: 83px;

  &__title {
    font-size: 38px;
    font-weight: 300;
    line-height: 50px;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: styles.$color-grey-2;
    font-size: 16px;
  }

  &__loadingLine {
    margin-top: 20px;
    width: 150px;
    height: 40px;
  }

  &__buttons {
    margin-top: 20px;
    gap: 25px;
  }

  &__getStarted {
    min-width: 100px;
    width: 160px;
    height: 40px;
  }

  &__explore {
    min-width: 165px;
    width: 185px;
    height: 40px;
  }

  &__pic {
    &._small,
    &._medium {
      display: none;
    }
  }

  &__social {
    position: absolute;
    top: 55px;
    left: 25px;
  }
}

@media (max-width: styles.$lg-min) {
  .firstScreen {
    &__pic {
      &._big,
      &._small {
        display: none;
      }
      &._medium {
        display: block;
      }
    }
  }
}

@media (max-width: 880px) {
  .firstScreen {
    &__buttons {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }
}

@media (max-width: 860px) {
  .firstScreen {
    flex-direction: column-reverse;
    gap: 20px;

    &__title {
      text-align: center;
    }

    &__subtitle {
      text-align: center;
    }

    &__pic {
      padding-right: 50px;
    }

    &__buttons {
      flex-direction: row;
      gap: 25px;
      margin: 20px auto 0 auto;
    }
  }
}

@media (max-width: 640px) {
  .firstScreen {
    align-items: flex-start;
    gap: 0;
    padding-top: 25px;
    padding-bottom: 40px;

    &__title {
      text-align: left;
      font-size: 24px;
      line-height: 1.3;
    }

    &__subtitle {
      text-align: left;
      font-size: 14px;
    }

    &__buttons {
      margin: 20px 0 0;
    }

    &__pic {
      padding-right: 0;

      &._big,
      &._medium {
        display: none;
      }
      &._small {
        display: block;
        position: relative;
        left: -25px;
        margin: 0 auto;
      }
    }

    &__social {
      position: static;
      order: 1;
      margin: 0 auto 20px;
    }
  }
}

@media (max-width: 420px) {
  .firstScreen {
    &__buttons {
      flex-direction: column-reverse;
      gap: 10px;
    }
  }
}
