@use "@causevest/ui-kit/styles";

.wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  background: styles.$color-white;
  border: 1px solid styles.$color-light-grey;
  border-left: none;
  border-right: none;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 15px 25px;
    color: styles.$color-grey-7;
    font-size: 14px;

    &:hover {
      background: transparent;
    }
  }
}

@media (max-width: styles.$sm) {
  .inner {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;

    button {
      min-width: 100px;
    }
  }
}
